import { ConnectionStatus } from '@capacitor/network';
import {
  PushNotificationSchema,
} from '@capacitor/push-notifications';

export namespace AppActions {

  export namespace PushNotifications {

    export class Received {
      static readonly type = '[Auth] Push Notifications Received';

      constructor(public payload: PushNotificationSchema) {
      }
    }

    export class ActionPerformed {
      static readonly type = '[Auth] Push Notifications Action Performed';

      constructor(public payload: PushNotificationSchema) {
      }
    }
  }

  export class NetworkStatusChanged {
    static readonly type = '[App] NetworkStatusChanged';

    constructor(public payload: ConnectionStatus) {
    }
  }

  export class Logout {
    static readonly type = '[Auth] Logout';
  }

  export class ResetState {
    static readonly type = '[Auth] Reset State';
  }
}
