import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { filter, finalize, switchMap } from 'rxjs/operators';
import { LoadingIndicatorService } from '../services/loading-indicator.service';
import { PushNotificationService } from '../services/push-notification.service';
import { Router } from '@angular/router';
import {
  ArticlesService,
} from '../../tabs/sections/shared/services/articles.service';

@Injectable()
export class LoadingIndicatorInterceptor implements HttpInterceptor {

  private _loadingIndicatorService = inject(LoadingIndicatorService);
  private _router = inject(Router);

  intercept(
      httpRequest: HttpRequest<any>,
      next: HttpHandler,
  ): Observable<HttpEvent<any>> {

    if (this._shouldSkipLoadingIndicator(httpRequest.url)) {
      return next.handle(httpRequest);
    }

    return this._loadingIndicatorService
               .present()
               .pipe(
                   switchMap(() => this._handleRequest(httpRequest, next)),
               );
  }

  private _shouldSkipLoadingIndicator(url: string): boolean {

    if (this._isRequestingFromArticlePage() && this._isLastSegmentNumber(url)) {
      return true;
    }

    const urlsToSkip = [
      PushNotificationService.apiPath,
      'statistics',
    ];

    return urlsToSkip.some((path) => url.includes(path));
  }

  private _handleRequest(
      httpRequest: HttpRequest<any>,
      next: HttpHandler,
  ) {
    return next.handle(httpRequest)
               .pipe(
                   filter((event) => event instanceof HttpResponse),
                   finalize(() => {
                     this._loadingIndicatorService.dismiss();
                   }),
               );
  }

  private _isRequestingFromArticlePage(): boolean {
    const routerUrl = this._router.url.toString();
    const articlePagePathRegex = /\/sections\/\d+\/\d+$/;
    return articlePagePathRegex.test(routerUrl);
  }

  private _isLastSegmentNumber(str: string) {

    if (!str && !str.includes(ArticlesService.articlesPath)) {
      return false;
    }

    const segments = str.split('/');
    const lastSegment = segments[segments.length - 1];
    const regex = /\d+$/;
    return regex.test(lastSegment);
  }
}
