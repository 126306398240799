import { inject, Injectable } from '@angular/core';
import {
  HideOptions,
  ShowOptions,
  SplashScreen,
} from '@capacitor/splash-screen';
import { Platform } from '@ionic/angular';

@Injectable({ providedIn: 'root' })
export class SplashScreenService {

  private _platform = inject(Platform);

  isSplashScreenAvailable(): boolean {
    return this._platform.is('mobile');
  }

  show(showOptions?: ShowOptions): Promise<void> {
    return SplashScreen.show({
      ...showOptions,
      autoHide: true,
    });
  }

  hide(hideOptions?: HideOptions): Promise<void> {
    return SplashScreen.hide(hideOptions);
  }
}
