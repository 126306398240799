import { inject, Injectable } from '@angular/core';
import { ApiService } from '../../../../shared/services/api.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ArticleResponseModel } from '../../models/article-response.model';
import { ArticleModel } from '../../models/article.model';

@Injectable()
export class ArticlesService {

  static articlesPath = 'posts';
  static highlightArticlesPath = `posts/order`;
  static mostViewedArticlesPath = `posts/most-viewed`;

  private _apiService = inject(ApiService);

  getArticles$(categoryId?: string): Observable<ArticleModel[]> {

    const path = (categoryId)
                 ? `${ ArticlesService.articlesPath }?category_id=${ categoryId }`
                 : ArticlesService.articlesPath;

    return this._apiService
               .get(path)
               .pipe(
                   map((articlesResponse: ArticleResponseModel[]) =>
                       articlesResponse
                           .filter((article) =>
                               this._hasArticleValidCategory(
                                   article,
                                   categoryId,
                               ),
                           )
                           .filter((article) =>
                               !this._isHighlightArticle(article))
                           .map((article) =>
                               new ArticleModel(article),
                           ),
                   ),
               );
  }

  getHighlightArticles$(): Observable<ArticleModel[]> {

    return this._apiService
               .get(ArticlesService.highlightArticlesPath)
               .pipe(
                   map((articlesResponse: ArticleResponseModel[]) =>
                       articlesResponse
                           .filter((article) =>
                               this._hasArticleValidCategory(article),
                           )
                           .filter(this._isHighlightArticle)
                           .map((article) =>
                               new ArticleModel(article),
                           ),
                   ),
               );
  }

  getMostViewedArticles$(): Observable<ArticleModel[]> {

    return this._apiService
               .get(ArticlesService.mostViewedArticlesPath)
               .pipe(
                   map((articlesResponse: ArticleResponseModel[]) =>
                       articlesResponse
                           .filter((article) =>
                               this._hasArticleValidCategory(article),
                           )
                           .map((article) =>
                               new ArticleModel(article),
                           ),
                   ),
               );
  }

  getArticle$(id: string): Observable<ArticleModel> {

    return this._apiService
               .get(`${ ArticlesService.articlesPath }/${ id }`)
               .pipe(
                   map((articleResponse: ArticleResponseModel) =>
                       new ArticleModel(articleResponse),
                   ),
               );
  }

  private _hasArticleValidCategory(
      article: ArticleResponseModel,
      categoryId?: string,
  ): boolean {

    // to ensure the rule that all articles must have a category
    if (!article.category || !article.category.id) {
      return false;
    }

    // if no category is selected, return all articles
    if (!categoryId) {
      return true;
    }

    const isFromCategory = article.category.id.toString()
                           === categoryId;
    const isFromRootCategory = article.category.parent_id.toString()
                               === categoryId;
    return isFromCategory || isFromRootCategory;
  }

  private _isHighlightArticle(article: ArticleResponseModel): boolean {
    return article.star;
  }
}
