import { inject, Injectable } from '@angular/core';
import { ConnectionStatus, Network } from '@capacitor/network';
import { Store } from '@ngxs/store';
import { StoreActions } from '../store/store.actions';
import { AppActions } from '../../app.actions';
import { AppState } from '../../app.state';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {

  private _store = inject(Store);

  constructor() {
    Network.addListener('networkStatusChange', (status) => {
      this._store.dispatch(new AppActions.NetworkStatusChanged(status));
      this._store.dispatch(new StoreActions.ResetLoadedState());
      console.log('Network status changed', status);
    });
  }

  async getStatus(): Promise<ConnectionStatus> {
    return await Network.getStatus();
  }

  getStatusFromState(): ConnectionStatus {
    return this._store.selectSnapshot(AppState.getNetworkStatus);
  }
}
