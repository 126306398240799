import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApiResponseModel } from '../models/api-response.model';
import {
  ApiErrorDetail,
  ApiErrorResponseModel,
} from '../models/api-error-response.model';

@Injectable({
  providedIn: 'root',
})
export class ApiService {

  private _httpClient = inject(HttpClient);

  get<T>(url: string): Observable<T> {

    return this._httpClient
               .get<ApiResponseModel>(url)
               .pipe(
                   map((response: ApiResponseModel) => this._getResponseData(
                       response)),
                   catchError((errorResponse: ApiErrorResponseModel) => {
                     throw this._errorHandler({
                       method: 'GET',
                       errorResponse,
                     });
                   }),
               );
  }

  post<T>(url: string, body: any): Observable<T> {

    return this._httpClient
               .post<ApiResponseModel>(url, body)
               .pipe(
                   map((response: ApiResponseModel) => this._getResponseData(
                       response)),
                   catchError((errorResponse: ApiErrorResponseModel) => {
                     throw this._errorHandler({
                       method: 'POST',
                       errorResponse,
                     });
                   }),
               );
  }

  put<T>(url: string, body: any): Observable<T> {

    return this._httpClient
               .put<ApiResponseModel>(url, body)
               .pipe(
                   map((response: ApiResponseModel) => this._getResponseData(
                       response)),
                   catchError((errorResponse: ApiErrorResponseModel) => {
                     throw this._errorHandler({
                       method: 'PUT',
                       errorResponse,
                     });
                   }),
               );
  }

  delete<T>(url: string, body?: any): Observable<T> {

    return this._httpClient
               .delete<ApiResponseModel>(url, { body })
               .pipe(
                   map((response: ApiResponseModel) => this._getResponseData(
                       response)),
                   catchError((errorResponse: ApiErrorResponseModel) => {
                     throw this._errorHandler({
                       method: 'DELETE',
                       errorResponse,
                     });
                   }),
               );
  }

  private _getResponseData(response: ApiResponseModel | any): any {
    if (!response) {
      throw new Error('API response is empty');
    }

    if (!response.data) {
      return response;
    }

    return response.data;
  }

  private _errorHandler({
    method,
    errorResponse,
  }: {
    method: string;
    errorResponse: ApiErrorResponseModel;
  }): ApiErrorDetail {

    console.log(`API ${ method } Error:`, errorResponse);

    const unknownErrorMessage = 'Ocorreu um erro inesperado, por favor, tente mais tarde';
    const isUnknownError = errorResponse.statusText === 'Unknown Error';

    if (isUnknownError) {
      return { message: unknownErrorMessage };
    }

    const hasSpecifiedError = !!Object.keys(errorResponse.error).length;

    if (hasSpecifiedError) {
      return errorResponse.error;
    }

    return {
      message: errorResponse.message || unknownErrorMessage,
    };
  }
}
