import { Injectable } from '@angular/core';
import { PreferencesService } from './preferences.service';
import { Observable } from 'rxjs';
import config from '../../../capacitor.config';

@Injectable({ providedIn: 'root' })
export class TokenStorageService {

  private _storageTokenKey = config.appName.replace(/\s/g, '');

  constructor(private _storageService: PreferencesService) {
  }

  setApiTokenOnStorage(token: string): Observable<void> {

    return this._storageService.set(this._storageTokenKey, token);
  }

  getApiTokenOnStorage(): Observable<string> {

    return this._storageService.get(this._storageTokenKey);
  }

  clearApiToken(): Observable<void> {

    return this._storageService.remove(this._storageTokenKey);
  }
}
