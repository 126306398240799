import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { LoadingOptions } from '@ionic/core';
import { defer, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingIndicatorService {

  private activeLoading: HTMLIonLoadingElement;
  private _isPresenting = false;
  private _loadingCount = 0;

  constructor(private _loadingController: LoadingController) {
  }

  dismiss(): void {

    this._loadingCount--;

    if (!this._isPresenting) {
      return;
    }

    if (this._hasPendingLoadings()) {
      return;
    }

    this.activeLoading.dismiss()
        .then(() => this._isPresenting = false);
  }

  present(loadingOptions?: LoadingOptions): Observable<boolean> {

    this._loadingCount++;

    if (this._isPresenting) {
      return of(false);
    }

    return defer(() => {
      this._isPresenting = true;
      return this._loadingController
                 .create({
                   ...loadingOptions,
                   translucent: true,
                   spinner: 'crescent',
                 })
                 .then((loading: HTMLIonLoadingElement) => {
                   this.activeLoading = loading;
                   return this.activeLoading.present()
                              .then(() => this._isPresenting);
                 });
    });
  }

  private _hasPendingLoadings(): boolean {
    return this._loadingCount > 0;
  }
}
