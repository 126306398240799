export namespace StoreActions {

  export class ResetLoadedState {
    static readonly type = '[Store] Reset Loaded State';
  }

  export class ResetStore {
    static readonly type = '[App] Reset Store';
  }
}
