import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { environment } from '../../environments/environment';
import { defer, from, Observable, of } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class PreferencesService {

  private _isPreferencesSetup = false;

  configurePreferences(group = environment.appId): Observable<boolean> {

    if (this._isPreferencesSetup) {
      return of(true);
    }

    return defer(() => {
      return from(Preferences.configure({ group })
                         .then(() => {
                           this._isPreferencesSetup = true;
                           return this._isPreferencesSetup;
                         }))
          .pipe(
              filter((isConfigurated) => isConfigurated),
          );
    });
  }

  get(key: string): Observable<any> {

    return this.configurePreferences()
               .pipe(
                   switchMap(() => {
                     return defer(() => {
                       return from(Preferences.get({ key })
                                          .then(({ value }) => JSON.parse(value)));
                     });
                   }),
                   catchError((error) => {
                     console.warn(error);
                     return this.remove(key);
                   }),
               );
  }

  set(key: string, value: any): Observable<void> {

    return this.configurePreferences()
               .pipe(
                   switchMap(() => {
                     return defer(() => {
                       return from(Preferences.set({
                         key,
                         value: JSON.stringify(value),
                       }));
                     });
                   }),
               );
  }

  remove(key: string): Observable<void> {

    return this.configurePreferences()
               .pipe(
                   switchMap(() => {
                     return defer(() => {
                       return from(Preferences.remove({ key }));
                     });
                   }),
               );
  }

  clear(): Observable<void> {

    return this.configurePreferences()
               .pipe(
                   switchMap(() => {
                     return defer(() => {
                       return from(Preferences.clear());
                     });
                   }),
               );
  }
}
