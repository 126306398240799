import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable()
export class SetDomainInterceptor implements HttpInterceptor {

  intercept(
      httpRequest: HttpRequest<any>,
      next: HttpHandler,
  ): Observable<HttpEvent<any>> {

    if (this._shouldNotSetApiDomain(httpRequest.url)) {
      return next.handle(httpRequest);
    }

    const updatedRequest = httpRequest
        .clone({
          url: `${ environment.apiBaseUrl }/${ httpRequest.url }`,
        });

    return next.handle(updatedRequest);
  }

  private _shouldNotSetApiDomain(url: string): boolean {

    const forbiddenResources = ['assets', 'json'];

    return forbiddenResources
        .some((forbiddenResource) => url.includes(forbiddenResource));
  }
}
