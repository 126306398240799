import { inject, Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';
import { Observable, of } from 'rxjs';
import {
  ActionPerformed,
  PermissionStatus,
  PushNotifications,
  PushNotificationSchema,
  Token,
} from '@capacitor/push-notifications';
import { map } from 'rxjs/operators';
import { ApiService } from './api.service';
import {
  FcmRegisterResponseModel,
} from '../models/fcm-register-response.model';
import { Store } from '@ngxs/store';
import { AppActions } from '../../app.actions';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {

  static apiPath = 'customers/anonymous-fcm';

  private _apiService = inject(ApiService);
  private _store = inject(Store);

  static isPushNotificationsAvailable() {
    return Capacitor.isPluginAvailable('PushNotifications');
  }

  register() {

    if (!PushNotificationService.isPushNotificationsAvailable()) {
      console.warn('PushNotifications is not available on Web');
      return;
    }

    // Needs to be registered before any Push Notifications actions
    this._registerPushNotificationListeners();

    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications
        .requestPermissions()
        .then((status: PermissionStatus) => {

          if (!this._isPermissionGranted(status)) {
            return;
          }

          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        });
  }

  private _isPermissionGranted(permissionStatus: PermissionStatus) {
    return permissionStatus.receive === 'granted';
  }

  private _registerPushNotificationListeners() {

    if (!PushNotificationService.isPushNotificationsAvailable()) {
      console.warn('PushNotifications is not available on Web');
      return;
    }

    // On success, we should be able to receive notifications
    PushNotifications.addListener(
        'registration',
        (token: Token) => {

          if (!token.value) {

            // This is need because if we pass an empty token, we overwrite the
            // current token with an empty value, preventing user from receiving
            // a notification
            console.warn('Could not receive the token :(');
            return;
          }

          console.log(`Push registration success, token: ${ token.value }`);

          this._registerFcmToken(token.value);
        },
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener(
        'registrationError',
        (error: any) => {
          console.log('Error on registration: ' + JSON.stringify(error));
        },
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
        'pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          this._store.dispatch(new AppActions.PushNotifications.Received(notification));
          console.log('Push received: ' + JSON.stringify(notification));
        },
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
        'pushNotificationActionPerformed',
        (actionPerformed: ActionPerformed) => {
          this._store.dispatch(new AppActions.PushNotifications.ActionPerformed(actionPerformed.notification));
          console.log('Push action performed: ' + JSON.stringify(actionPerformed));
        },
    );
  }

  private async _removeAllListeners() {
    await PushNotifications.removeAllListeners();
  }

  private _registerFcmToken(token: string) {

    return this._apiService
               .post(PushNotificationService.apiPath, { fcm: token })
               .subscribe({
                 next: (response: FcmRegisterResponseModel) =>
                     console.log(`FCM Token registered in API ${ response.fcm }`),
                 error: err => console.log(err),
               });
  }

  private _removeFcmToken$(): Observable<boolean> {

    if (!PushNotificationService.isPushNotificationsAvailable()) {
      console.warn('PushNotifications is not available on Web');
      return of(false);
    }

    // Passing an empty FCM token is enough to clean user token when it logout
    // from app
    return this._apiService
               .delete(
                   PushNotificationService.apiPath, { fcm: '' },
               )
               .pipe(map(() => true));
  }
}
