import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'onboarding',
    loadChildren: () => import('./onboarding/onboarding.module').then(m => m.OnboardingPageModule),
  },
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule),
  },
  // Welcome
  // Login
  // Register
  // First Access
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(
        routes,
        {
          preloadingStrategy: PreloadAllModules,
          // enableTracing: true,
        },
    ),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
