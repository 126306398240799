import { inject, Injectable } from '@angular/core';
import {
  Action,
  Selector,
  State,
  StateContext,
  StateToken,
  Store,
} from '@ngxs/store';
import { ConnectionStatus } from '@capacitor/network';
import { AppActions } from './app.actions';
import { StoreActions } from './shared/store/store.actions';
import { NetworkService } from './shared/services/network.service';

const APP_STATE_TOKEN = new StateToken<AppStateModel>('AppState');

export class AppStateModel {
  networkStatus: ConnectionStatus;
}

const defaultState: AppStateModel = {
  networkStatus: {
    connected: false,
    connectionType: 'none',
  },
};

@State<AppStateModel>({
  name: APP_STATE_TOKEN,
  defaults: defaultState,
})
@Injectable()
export class AppState {

  private _store = inject(Store);
  private _networkService = inject(NetworkService);

  constructor() {
    this._networkService
        .getStatus()
        .then((status) => {
          // initial network status
          this._store.dispatch(new AppActions.NetworkStatusChanged(status));
        });
  }

  @Selector()
  static getNetworkStatus(state: AppStateModel): ConnectionStatus {
    return state.networkStatus;
  }

  @Action(AppActions.NetworkStatusChanged)
  networkStatusChanged(
      ctx: StateContext<AppStateModel>,
      action: AppActions.NetworkStatusChanged,
  ): void {
    ctx.patchState({ networkStatus: action.payload });
  }

  @Action([AppActions.ResetState, StoreActions.ResetStore])
  resetState(ctx: StateContext<AppStateModel>) {
    ctx.setState({
      ...defaultState,
    });
  }
}
