import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { TokenStorageService } from '../../services/token-storage.service';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private _tokenStorageService: TokenStorageService) {
  }

  intercept(
      httpRequest: HttpRequest<any>,
      next: HttpHandler,
  ): Observable<HttpEvent<any>> {

    return this._tokenStorageService
               .getApiTokenOnStorage()
               .pipe(
                   switchMap((token) => {

                     if (!token) {

                       return next.handle(httpRequest);
                     }

                     // Clone the request and set the new header in one step.
                     const authRequest = httpRequest
                         .clone({
                           setHeaders:
                               {
                                 Authorization: `Bearer ${ token }`,
                               },
                         });

                     // send cloned request with header to the next handler.
                     return next.handle(authRequest);
                   }));
  }
}
