import { Component, inject } from '@angular/core';
import {
  PushNotificationService,
} from './shared/services/push-notification.service';
import { App } from '@capacitor/app';
import { StoreActions } from './shared/store/store.actions';
import { Actions, ofActionDispatched, Store } from '@ngxs/store';
import { SplashScreenService } from './shared/services/splash-screen.service';
import { Platform } from '@ionic/angular';
import { NetworkService } from './shared/services/network.service';
import { AppActions } from './app.actions';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  private _platform = inject(Platform);
  private _pushNotificationService = inject(PushNotificationService);
  private _splashScreenService = inject(SplashScreenService);
  private _store = inject(Store);
  private _networkService = inject(NetworkService);

  constructor() {

    this._showSplashScreen();

    this._platform
        .ready()
        .then(() => {
          this.appReadyHandler();
        });
  }

  appReadyHandler() {
    this._handleAppOnResume();
    this._pushNotificationService.register();
    this._splashScreenService.hide();
  }

  private _showSplashScreen() {
    if (this._splashScreenService.isSplashScreenAvailable()) {
      this._splashScreenService.show();
    }
  }

  private _handleAppOnResume() {

    const listenAppOnResume = () => {

      this._networkService
          .getStatus()
          .then(({ connected }) => {

            if (!connected) {
              return;
            }

            this._store.dispatch(new StoreActions.ResetLoadedState());
          });
    };

    App.addListener('resume', listenAppOnResume);
  }
}
