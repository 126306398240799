import { ShareOptions } from '@capacitor/share';
import config from '../../capacitor.config';

export class Environment {

  production: boolean;
  apiBaseUrl: string;
  appVersion = '0.0.14';
  appId = config.appId;
  shareOptions: ShareOptions = {
    title: 'Liberal App',
    dialogTitle: 'Compartilhe essa experiência!',
    text: 'Aqui, você vai ficar informado com o noticiário de Americana, Santa Bárbara, região, o Brasil e o mundo!',
  };

  constructor(options: {
    production: boolean;
    apiBaseUrl: string;
    name?: string;
  }) {
    this.production = options.production;
    this.apiBaseUrl = options.apiBaseUrl;
    this.setEnvironmentName(options.name);
  }

  setEnvironmentName(name = 'dev') {

    if (this.production) {
      return;
    }

    this.appVersion = `${ this.appVersion }-${ name }`;
  }
}
